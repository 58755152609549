<!--
 * @Author: your name
 * @Date: 2021-07-29 11:05:20
 * @LastEditTime: 2021-09-15 15:32:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\about\index.vue
-->
<template>
  <div class="about">
    <div class="container flex-h">
      <ul class="tabs">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ active: index == currentIndex }"
          @click="getData(item.id, index)"
        >
          <span>{{ item.title }}</span>
        </li>
      </ul>
      <div class="main">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAboutUs, getAboutDetail } from "@/api/index";
export default {
  data() {
    return {
      tabs: [],
      currentIndex: 0,
      content: "",
    };
  },
  methods: {
    getList() {
      getAboutUs().then((res) => {
        this.tabs = res.data.list;
        this.getData(this.tabs[0].id, 0);
      });
    },
    getData(id, index) {
      this.currentIndex = index;
      getAboutDetail(id).then((res) => {
        this.content = res.data.content;
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none
}
.about {
  background: #f6f6f6;
  padding-top: 38px;
  padding-bottom: 25px;
  .container {
    justify-content: space-between;
    .tabs {
      width: 224px;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 15px 24px;
      li {
        width: 100%;
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*不换行*/
        text-overflow: ellipsis; /*超出部分文字以...显示*/
        border-bottom: 1px dashed #979797;
        text-align: center;
        color: #0a0a0a;
        &:last-child {
          border-bottom: none;
        }
        span {
          height: 50px;
          line-height: 50px;
          cursor: pointer;

          font-size: 20px;
        }
        &.active {
          color: #ff6e6e;
        }
      }
    }
    .main {
      width: 956px;
      background: #ffffff;
      overflow-x: auto;
      border-radius: 8px;
      padding:30px 22px;
    }
  }
}
</style>